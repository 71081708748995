<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <div class="col-lg-3">
      <div class="card card-inicio mb-5 mb-xxl-8 h-100">
        <div class="card-body pt-9 pb-0">
          <!--begin::Details-->
          <div class="h-50">
            <div class="d-flex flex-wrap flex-sm-nowrap mb-3 border-bottom">
              <!--begin: Pic-->
              <div class="me-7">
                <div
                  class="symbol symbol-50px symbol-fixed symbol-circle position-relative"
                >
                  <img
                    class="border border-2 border-azul"
                    src="media/avatars/blank.png"
                    alt="image"
                  />
                </div>
              </div>
              <!--end::Pic-->

              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div
                  class="d-flex justify-content-between align-items-start flex-wrap mb-2"
                >
                  <!--begin::User-->
                  <div class="d-flex flex-column">
                    <!--begin::Name-->
                    <div class="d-flex align-items-center">
                      <a href="#" class="texto-gris-1 fs-2 me-1">Max Smith</a>
                    </div>
                    <!--end::Name-->

                    <!--begin::Info-->
                    <div class="d-flex flex-wrap pe-2">
                      <a
                        href="#"
                        class="d-flex align-items-center text-blue me-5 mb-2"
                      >
                        Administrador
                      </a>
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::User-->
                </div>
                <!--end::Title-->
              </div>
              <!--end::Info-->
            </div>
            <div class="overflow-auto">
              <ul
                class="nav-stretch nav-tabs border-transparent p-0 flex-nowrap"
              >
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'profile-overview' }"
                    class="nav-link me-6 w-100 rounded-pill"
                    active-class="active"
                    style="font-size: 16px; color: #999999"
                  >
                    <i
                      style="font-size: 31px"
                      class="bi-person-circle me-3 fs-1"
                    />
                    Perfil
                  </router-link>
                  <!--end::Nav item-->
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'profile-equipos' }"
                    class="nav-link me-6 w-100 rounded-pill"
                    active-class="active"
                    style="font-size: 16px; color: #999999"
                  >
                    <span class="svg-icon svg-icon-4 me-3">
                      <i style="font-size: 31px" class="bi bi-hdd fs-1" />
                    </span>
                    Mis equipos
                  </router-link>
                  <!--end::Nav item-->
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'profile-campaigns' }"
                    class="nav-link me-6 w-100 rounded-pill"
                    active-class="active"
                    style="font-size: 16px; color: #999999"
                  >
                    <span class="svg-icon svg-icon-4 me-3">
                      <!-- <inline-svg src="media/icons/duotune/communication/com006.svg" /> -->
                      <i
                        style="font-size: 31px"
                        class="`bi bi-file-richtext fs-1 text-muted`"
                      />
                    </span>
                    Bitacora
                  </router-link>
                  <!--end::Nav item-->
                </li>
                <li class="nav-item d-block pe-6">
                  <button
                    class="nav-link me-6 py-3 w-100 d-flex justify-content-between bottonInformes"
                    active-class="active"
                    style="font-size: 16px"
                    @click="despliegueInformes"
                    :class="{ active: mostrar }"
                  >
                    <div class="informes d-flex">
                      <span class="svg-icon svg-icon-4 me-3">
                        <i
                          style="font-size: 31px"
                          class="`bi bi-clipboard-data fs-1 text-muted`"
                        />
                      </span>
                      <p>Informes</p>
                    </div>
                    <i class="bi bi-chevron-down"></i>
                  </button>
                  <div
                    v-if="mostrar == true"
                    class="px-3 py-4 despliegueInformes"
                  >
                    <div>
                      <router-link
                        :to="{ name: 'profile-informes' }"
                        class="nav-link me-6 w-100 text-white rounded-pill px-10"
                        active-class="active"
                        style="
                          font-size: 16px;
                          border-color: transparent !important;
                        "
                      >
                        -Informes programables
                      </router-link>
                      <router-link
                        :to="{ name: 'descargar-informe' }"
                        class="nav-link me-6 w-100 text-white rounded-pill px-10"
                        active-class="active"
                        style="
                          font-size: 16px;
                          border-color: transparent !important;
                        "
                      >
                        -Descargar informe
                      </router-link>
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <router-link
                    :to="{ name: 'profile-mi-grupo' }"
                    class="nav-link me-6 w-100 rounded-pill"
                    active-class="active"
                    style="font-size: 16px; color: #999999"
                  >
                    <span class="svg-icon svg-icon-4 me-3">
                      <i
                        style="font-size: 31px"
                        class="`bi bi-people-fill fs-1 text-muted`"
                      />
                    </span>
                    Mis grupos
                  </router-link>
                  <!--end::Nav item-->
                </li>
                <!--begin::Nav item-->
                <!-- <li v-for="(data, key) in menu" :key="key" class="nav-item">
                  <router-link
                    :to="{ name: data.ruta }"
                    class="nav-link me-6 w-100 rounded-pill text-active-white"
                    active-class="active"
                    style="font-size: 16px; color: #999999"
                  >
                    <span class="svg-icon svg-icon-4 me-3">
                      <i
                        style="font-size: 31px"
                        :class="`bi bi-${data.icon} fs-1 text-muted`"
                      />
                    </span>
                    {{ data.name }}
                  </router-link>
                </li> -->
                <!--end::Nav item-->
              </ul>
            </div>
          </div>
          <!--begin::Navs-->
          <div class="d-flex align-items-end h-50">
            <button
              class="btn bg-white fw-normal border-top w-100 text-start align-items-center px-0 mb-3"
              style="font-size: 16px; color: #999999"
              @click="signOut()"
            >
              <i class="bi bi-box-arrow-left text-blue fs-1"></i>
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 h-calc">
      <router-view></router-view>
    </div>
  </div>
  <!--end::Dashboard-->
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "dashboard",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    const mostrar = ref(false);
    const despliegueInformes = () => {
      mostrar.value = !mostrar.value;
    };
    const menu = [
      { name: "Perfil", icon: "person-circle", ruta: "profile-overview" },
      { name: "Mis equipos", icon: "hdd", ruta: "profile-equipos" },
      { name: "Bitácora", icon: "file-richtext", ruta: "profile-campaigns" },
      {
        name: "Informes",
        icon: "clipboard-data",
        ruta: "profile-informes-mosaico",
      },
      { name: "Mi grupo", icon: "people-fill", ruta: "profile-mi-grupo" },
    ];

    return {
      menu,
      signOut,
      despliegueInformes,
      mostrar,
    };
  },
});
</script>

<style lang="scss" scoped>
.h-calc {
  height: calc(100vh - 226px);
}
.nav-item a {
  margin-left: 0 !important;
}
li a.active {
  background-color: var(--blue) !important;
  color: white !important;
}
li button {
  background-color: white;
  color: #999999;
  .informes {
    p {
      color: #999999 !important;
    }
  }
}
li button.active {
  border-radius: 18px 18px 0 0;
  border: 1px solid var(--blue) !important;
  background-color: var(--blue) !important;
  color: white !important;
  .informes {
    p {
      color: white !important;
    }
  }
  span {
    i {
      color: white !important;
    }
  }
  i {
    color: white !important;
  }
}
li {
  .despliegueInformes {
    a.active {
      background-color: #0386bc !important;
      border-color: none !important;
    }
  }
}
li {
  a.active {
    i {
      color: white;
    }
  }
}
.despliegueInformes {
  background-color: #27aae1;
  color: white;
  border-radius: 0 0 18px 18px;
}
li a {
  width: 100%;
  border-radius: 50%;
  border-radius: 50rem !important;
}
.text-blue {
  color: #27aae1;
}
.texto-gris-1 {
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  font-size: 0.75rem;
}
</style>
